import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en.json';
import zhTranslations from './locales/zh.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(initReactI18next)
    .use(LanguageDetector) // 使用语言检测插件
    .init({
        resources: {
            en: {
                translation: enTranslations
            },
            zh: {
                translation: zhTranslations
            }
        },
        fallbackLng: 'en', // 当检测到的语言不可用时的回退语言
        detection: {
            order: ['navigator'], // 只使用浏览器的语言设置
        },
        interpolation: {
            escapeValue: false, // React 已经防止了 XSS
        },
    });

export default i18n;