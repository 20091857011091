import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
} from "react-icons/ai";
import { SiGitee, SiGitlab } from "react-icons/si";
import { MdEmail } from "react-icons/md";


function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by MainJayLai</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} MJ</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/mainjayLai"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icons"
                alt="github"
                aria-label="github"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://gitee.com/lmj2001"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icons"
                alt="gitee"
                aria-label="gitee"
              >
                <SiGitee />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://gitlab.com/JayMain"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icons"
                alt="gitlab"
                aria-label="gitlab"
              >
                <SiGitlab />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="mailto:ucas.mainjay@proton.me"
                style={{ color: "white" }}
                // target="_blank"
                // rel="noopener noreferrer"
                className="footer-social-icons"
                aria-label="email"
                alt="email"
              >
                <MdEmail />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
