import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { SiGitee } from "react-icons/si";
import { AiFillGitlab } from "react-icons/ai";


// give a function return icon component, params is the link string, if link include "gitee" return gitee icon, else return github or github icon
function getIconComponent(link) {
  if (link.includes("github")) {
    return (
      <>
        <BsGithub /> &nbsp; GitHub
      </>
    );
  } else if (link.includes("gitlab")) {
    return (
      <>
        <AiFillGitlab /> &nbsp; Gitlab
      </>
    );
  } else {
    return (
      <>
        <SiGitee /> &nbsp; Gitee
      </>
    );
  }
}

function ProjectCards(props) {
  const { t } = useTranslation();

  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title className="purple">{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          <strong className="light-purple">{t("projects.description")}</strong>&nbsp;<span style={{ fontFamily: 'Helvetica Neue, Arial, sans-serif', letterSpacing: '0.2px' }}>{props.description}</span>
          <br />
          <br />
          <strong className="light-purple">{t("projects.technique")}</strong>&nbsp;<strong style={{ fontFamily: 'inherit', letterSpacing: '0.8px' }}><i>{props.technique}</i></strong>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button variant="primary" href={props.ghLink} target="_blank">
          {getIconComponent(props.ghLink)}
        </Button>
        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
        {props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Demo"}
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
}
export default ProjectCards;
