import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { useTranslation } from 'react-i18next';

function AboutCard() {
  const { t } = useTranslation();

  // 将 activitiesList 转换为数组
  const activitiesList = t('about.activitiesList', { returnObjects: true }) || [];

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t('about.intro')}
            <br />
            <br />
            {t('about.employment')}
            <br />
            <br />
            {t('about.education')}
            <br />
            <br />
            {t('about.activities')}
          </p>
          <ul>
            {activitiesList.map((activity, index) => (
              <li key={index} className="about-activity">
                <ImPointRight /> {activity}
              </li>
            ))}
          </ul>

          <br />
          <br />

          <p style={{ color: "purple" }}>
            {t('about.quote')}
          </p>
          <footer className="blockquote-footer">{t('about.author')}</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
