import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import chatify from "../../Assets/Projects/chatify.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";
// import editor from "../../Assets/Projects/codeEditor.png";
// import leaf from "../../Assets/Projects/leaf.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import emotion from "../../Assets/Projects/emotion.png";
import { useTranslation } from 'react-i18next';

const projectProps = [
  {
    "ghLink": "https://gitlab.com/JayMain/cc-uni-app",
    "technique": "uni-app  SpringBoot",
  },
  {
    "technique": "Vue.js  Node.js",
    "ghLink": "https://github.com/unimagin",
  },
  {
    "technique": "WXML  JavaScript",
    "ghLink": "https://gitee.com/lmj2001/birthday-greetings",
  },
  {
    "technique": "Vue.js  Echarts  JavaScript",
    "ghLink": "https://gitee.com/lmj2001/algorithm-analysis-front-end",
  },
  {
    "ghLink": "https://gitee.com/lmj2001/graduation-design",
    "technique": "React  Django  TensorFlow",
  },
  {
    "ghLink": "https://github.com/ucasers",
    "technique": "Golang  Gin  Gorm",
  },
];

function Projects() {
  const { t } = useTranslation();

  const projects = t('projects.projectsList', { returnObjects: true }) || [];


  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="dark-purple"> {t("projects.introduce")} </strong>
        </h1>
        <p style={{ color: "white" }}>
          {t("projects.intro")}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projects.map((proj, index) => (
            <Col md={4} className="project-card" key={index}>
              <ProjectCard
                imgPath={chatify}
                title={proj.title}
                description={proj.description}
                ghLink={projectProps[index].ghLink}
                demoLink={projectProps[index].demoLink}
                technique={projectProps[index].technique}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
